import * as React from 'react';
import withRoot from '../Modules/withRoot';
import Banner from '../Views/Banner'
import SelfIntroduction from '../Views/SelfIntroduction';
import Services from '../Views/Services';
import Footer from '../Views/Footer';
import BasicContactInfo from '../Views/BasicContactInfo';
import HolidayMessage from '../Views/HolidayMessage';

function Home() {
  return (
    <React.Fragment>
      {/* <HolidayMessage /> */}
      <Banner />
      <SelfIntroduction />
      <Services />
      <BasicContactInfo />
      
    </React.Fragment>
  );
}

// export default withRoot(Index);
export default withRoot(Home);