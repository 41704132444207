import * as React from 'react';
import withRoot from '../Modules/withRoot';
import Navigation from '../Views/Navigation';
import { Routes, Route } from 'react-router';
import Home from './Home';
import Booking from './Booking';
import Footer from '../Views/Footer';
import Error from './Error';
import Behandlingar from './Behandlingar';
// import Contact from './Contact';

function App() {
  return (
    <React.Fragment>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Booking" element={<Booking />} />
        {/* <Route path="/Behandlingar" element={<Behandlingar />} /> */}
        {/* <Route path="/Contact" element={<Contact />} /> */}
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default withRoot(App);